import { monthsInString } from './constants';

// returns random date not more than the current date
export const generatePrevDate = ({ timeStamp }: { timeStamp: string}): { displayDate: string } => {
  let newMonth: number;
  let newDate: number;
  const actualDateTime = new Date(timeStamp);
  const currentDateTime = new Date();
  const actualMonth = actualDateTime.getMonth();
  const currentMonth = currentDateTime.getMonth();
  const actualDate = actualDateTime.getDate();
  const currentDate = currentDateTime.getDate();
  if ((Math.abs(currentMonth - actualMonth) > 3)) newMonth = currentMonth - 3;
  else newMonth = actualMonth;

  if (actualDate > currentDate) newDate = currentDate;
  else newDate = actualDate;

  const monthString = monthsInString[newMonth > 0 ? newMonth : 0];
  const currentYear = new Date().getFullYear();
  return { displayDate: `${monthString} ${newDate}, ${currentYear}` };
};

export const formattedAuthorNameForArticle = (authorName: string): string => {
  return authorName && authorName !== 'Oneflare'
          ? `${authorName} | Oneflare`
            : 'Oneflare';
};
