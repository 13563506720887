import { styled } from 'styled-components';
import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import H2 from '@oneflare/flarekit/lib/components/H2';
import H4 from '@oneflare/flarekit/lib/components/H4';
import media from '@oneflare/flarekit/lib/libs/media';

export const RelatedGuidesStyled = styled.section`
  padding: 40px 20px 0;
  text-align: left;

  ${media.smUp`
    text-align: center;
    padding: 40px;
  `}

  ${media.lgUp`
    border-bottom: 1px solid ${({ theme }) => theme.color.cloud};
    padding: 80px;
  `}
`;

export const RelatedCostGuideAnchor = styled(Anchor)`
  margin: 0 auto;
`;

export const RelatedGuidesContainer = styled.section`
  ${media.smUp`
    max-width: 1120px;
    margin: 0 auto;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  `}
`;

export const RelatedGuidesImage = styled.img`
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  border-radius: 4px;
  width: 100%;
`;

export const RelatedGuidesDescription = styled(H4)`
  color: ${({ theme }) => theme.color.charcoal};
  display: block;
  font-family: ${({ theme }) => theme.font.secondaryBold};
  font-size: 16px;
  line-height: 1.5;
  margin: 8px 0 32px;
  text-align: left;

  ${media.smUp`
    font-size: 18px;
    margin: 24px 24px 0;
    text-align: center
  `}
`;

export const RelatedGuidesHeader = styled(H2)`
  color: ${({ theme }) => theme.color.charcoal};
  font-family: ${({ theme }) => theme.font.secondaryBold};
  font-size: 22px;
  margin-bottom: 16px;
  text-align: left;

  ${media.smUp`
    font-size: 24px;
    margin-bottom: 32px;
    text-align: center;
  `}

  ${media.mdUp`
    font-size: 28px;
  `}
`;

export const RelatedGuidesImageWrapper = styled.div`
  padding-bottom: 56.2%;
  position: relative;

  &:after {
    background: rgba(38, 49, 51, 0);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: background 0.4s ease;
    width: 100%;
  }
`;

export const RelatedGuidesCol = styled.div`
  margin: 0 0 16px;

  &:hover {
    ${RelatedGuidesDescription} {
      text-decoration: underline;
    }

    ${RelatedGuidesImageWrapper} {
      &:after {
        background: rgba(38, 49, 51, 0.2);
      }
    }
  }
`;
