import PropTypes from 'prop-types';
import {
  RelatedCostGuideAnchor,
  RelatedGuidesImage,
  RelatedGuidesDescription,
  RelatedGuidesHeader,
  RelatedGuidesStyled,
  RelatedGuidesContainer,
  RelatedGuidesImageWrapper,
  RelatedGuidesCol
} from './styled/RelatedGuides';

const propTypes = {
  guides: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    image: PropTypes.shape({
      medium: PropTypes.string.isRequired
    }).isRequired
  })),
  heading: PropTypes.string
};

const defaultProps = {
  heading: '',
  guides: []
};

const RelatedGuides = (props) => {
  const { heading, guides } = props;

  if (!guides && !heading) return null;

  return (
    <RelatedGuidesStyled>
      {heading
        && <RelatedGuidesHeader>{heading}</RelatedGuidesHeader>}

      {guides
        && (
        <RelatedGuidesContainer>
          {guides.map(({ link, image, description }) => (
            <RelatedGuidesCol lg={4} md={4} sm={12} key={link}>
              <RelatedCostGuideAnchor href={link} target="_blank">
                <RelatedGuidesImageWrapper>
                  <RelatedGuidesImage src={image.medium} alt={description} loading="lazy" />
                </RelatedGuidesImageWrapper>
                <RelatedGuidesDescription href={link} target="_blank">{description}</RelatedGuidesDescription>
              </RelatedCostGuideAnchor>
            </RelatedGuidesCol>
          ))}
        </RelatedGuidesContainer>
        )}
    </RelatedGuidesStyled>
  );
};

RelatedGuides.propTypes = propTypes;
RelatedGuides.defaultProps = defaultProps;

export default RelatedGuides;
