import { memo } from 'react';

// #region components
import Accordion from './components/Accordion';
import * as Styled  from './styled/FrequentlyAskedQuestions';
// #endregion components

// #region utils/helpers
import isEmpty from 'lodash/isEmpty';
import { escapeQuotes } from 'shared/utils/helpers';
// #endregion utils/helpers

export type FaqQuestions = Array<{
  title?: string | Array<string>;
  content?: string;
  addToSchema?: boolean;
}>;

type FrequentyAskQuestionsProps = {
  title?: string;
  className?: string;
  questions: FaqQuestions;
  downgradeHeadingLevel?: boolean;
};

function FrequentlyAskedQuestions ({
  title = '',
  questions,
  className = '',
  downgradeHeadingLevel = true
}: FrequentyAskQuestionsProps) {
  return (
    <section className={className}>
      <Schema questions={questions} />
      <Styled.FAQWrapper>
        <FAQTitle title={title} downgradeHeadingLevel={downgradeHeadingLevel} />
        <FAQAccordion questions={questions} downgradeHeadingLevel={downgradeHeadingLevel} />
      </Styled.FAQWrapper>
    </section>
  );
}

function FAQTitle({ title, downgradeHeadingLevel }: {downgradeHeadingLevel: boolean; title: string}) {
  if (!title) return null;
  if (downgradeHeadingLevel) {
    return <Styled.FAQDowngradedTitle>{title}</Styled.FAQDowngradedTitle>;
  }
  return <Styled.FAQTitle>{title}</Styled.FAQTitle>;
}

function FAQAccordion({
  questions, downgradeHeadingLevel
}: {
  questions: FaqQuestions;
  downgradeHeadingLevel: boolean;
}) {
  if (isEmpty(questions)) return null;
  return (
    <Styled.FAQAccordionSection>
      {questions.map(({ title, content }) => (
        <Accordion
          key={title as string}
          title={title}
          content={content}
          downgradeHeadingLevel={downgradeHeadingLevel}
        />
      ))}
    </Styled.FAQAccordionSection>
  );
}

function Schema({ questions }: {
  questions: FaqQuestions;
}) {
  return (
    <script
      data-testid="faq-schema"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [${questions.map(({ title, content, addToSchema }) => (addToSchema ? (
          `{
              "@type": "Question",
              "name": "${title}",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "${escapeQuotes(content)}"
              }
            }`
        ) : (
          `{
              "@type": "Question",
              "name": "${title}",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "${escapeQuotes(content.replace(/<[^>]*>?/gm, ''))}"
              }
            }`
        )))}]
        }`
      }}
    />
  );
}

export default memo(FrequentlyAskedQuestions);
