import media from '@oneflare/flarekit/lib/libs/media';
import { css } from 'styled-components';

const captionStyles = css`
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.color.spaceGrey};
`;

const wysiwygStyles = css`
  color: ${({ theme }) => theme.color.gunMetal};
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;

  img {
    width: 100%;
    margin: 40px 0;
    object-fit: cover;
    border-radius: 8px;
  }

  .wp-caption {
    img {
      margin: 0;
    }

    margin: 40px 0;
    width: 100% !important;
  }

  .wp-block-image {
    img {
      margin: 40px 0 0;
      height: auto !important;
    }

    &.is-resized img,
    .is-resized img {
      width: auto;
    }

    &.aligncenter,
    .aligncenter{
      display: flex;
      justify-content: center;
    }

    margin: 0;
    padding: 0;
  }

  figure {
    padding: 0;
    margin: 0;
  }

  table {
    table-layout: fixed;
    border-collapse: collapse;
    margin: 16px 0 0;
    width: 100%;
    text-align: left;
    overflow-x: auto;

    th {
      color: ${({ theme }) => theme.color.charcoal};
    }

    th,
    td {
      padding: 8px 0 8px 8px;
      border-bottom: 1px solid ${({ theme }) => theme.color.cloud};
    }

    tfoot th,
    tfoot td {
      border-bottom: none;
      color: ${({ theme }) => theme.color.silver};
      font-size: 14px;
      font-style: italic;
    }
  }

  a {
    color: ${({ theme }) => theme.color.infoDark};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  figcaption {
    margin: 8px 0 40px;
    ${captionStyles};
  }

  p {
    &.wp-caption-text {
      margin: 8px 0 0;
      ${captionStyles};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  iframe {
    width: 100%;
  }

  hr {
    margin: 24px 0;
    border: 1px solid ${({ theme }) => theme.color.cloud};
  }

  h3, h4 {
    margin-top: 40px;
  }

  ol  {
    margin: 40px 0 0;
    padding: 0;
    counter-reset: ol-counter;

    li {
      margin-bottom: 32px;
      list-style: none;
      position: relative;
      padding-left: 52px;

      :before {
        content: counter(ol-counter);
        counter-increment: ol-counter;
        position: absolute;
        color: ${({ theme }) => theme.color.primary};
        border-radius: 100%;
        border: 2px solid ${({ theme }) => theme.color.primary};
        width: 30px;
        height: 30px;
        line-height: 26px;
        font-weight: 600;
        left: 0;
        text-align: center;
        top: 0;
      }
    }
  }

  .inline-cta {
    background: ${({ theme }) => theme.color.tin};
    width: 100%;
    padding: 32px 20px;
    margin: 16px 0;
    border-radius: 8px;
    display: inline-block;

    ${media.mdUp`
      padding: 40px 32px;
    `}
  }

  .inline-cta-h2 {
    font-family: ${({ theme }) => theme.font.secondaryBold};
    color: ${({ theme }) => theme.color.charcoal};
    font-size: 18px;
    line-height: 1.5;
    margin: 0 0 16px;

    ${media.mdUp`
      font-size: 22px;
    `}
  }

  .inline-cta-button-link,
  .inline-cta-button {
    background-color: ${({ theme }) => theme.color.primary};
    border: 0;
    border-radius: 4px;
    box-shadow: none;
    color: ${({ theme }) => theme.color.white};
    cursor: pointer;
    display: inline-block;
    font-family: ${({ theme }) => theme.font.secondaryRegular};
    font-size: 16px;
    font-style: normal;
    letter-spacing: 0.2px;
    line-height: 1.5;
    opacity: 1;
    padding: 12px 24px;
    transition: all 0.1s linear;
    text-align: center;
    text-transform: none;
    user-select: none;
    width: 100%;

    &:focus,
    &:active {
      outline: 0;
    }

    &:hover {
      text-decoration: none;
      background: ${({ theme }) => theme.color.primaryDarker};
    }

    /* stylelint-disable */
    ${media.smUp`
      width: auto;
    `}
    /* stylelint-enable */
  }
`;

export default wysiwygStyles;
