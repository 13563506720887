import PropTypes from 'prop-types';
import {
  StatsFactStyled,
  StatsFigureStyled,
  StatsDescriptionStyled,
  StatsWrapper,
  H2Styled,
  StatsStyled,
  H3Styled,
  FactsStyled
} from './styled/CostStatsStyled';

const propTypes = {
  className: PropTypes.string,
  costFact: PropTypes.arrayOf(PropTypes.shape({
    fact: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  })),
  heading: PropTypes.string,
  subheading: PropTypes.string
};

const defaultProps = {
  className: null,
  heading: '',
  subheading: '',
  costFact: []
};

const CostStats = (props) => {
  const {
    className,
    costFact,
    heading,
    subheading
  } = props;

  if (!costFact && !heading && !subheading) return null;

  return (
    <StatsStyled className={className}>
      <StatsWrapper>
        {heading
          && <H2Styled>{heading}</H2Styled>}

        {subheading
          && <H3Styled>{subheading}</H3Styled>}

        {costFact
          && (
          <FactsStyled>
            {costFact.map(({ fact, description }) => (
              <StatsFactStyled key={fact}>
                <StatsFigureStyled>{fact}</StatsFigureStyled>
                <StatsDescriptionStyled>{description}</StatsDescriptionStyled>
              </StatsFactStyled>
            ))}
          </FactsStyled>
          )}
      </StatsWrapper>
    </StatsStyled>
  );
};

CostStats.propTypes = propTypes;
CostStats.defaultProps = defaultProps;

export default CostStats;
