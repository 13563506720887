import PropTypes from 'prop-types';
import { slugHelper, generateRefNameFor } from 'shared/utils/helpers';
import {
  H2Styled,
  ListStyled,
  ListItemStyled,
  LinkStyled,
  RightChevronStyled
} from './styled/QuestionsSummary';

const propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({
    subtitle: PropTypes.string.isRequired
  })),
  onClick: PropTypes.func.isRequired
};

const defaultProps = {
  articles: {
    subtitle: ''

  }
};

const QuestionsSummary = (props) => {
  const { articles, onClick } = props;

  if (!articles) return null;
  return (
    <>
      <H2Styled>{'What\'s on this page'}</H2Styled>
      <ListStyled>
        {articles.map((article) => (
          <ListItemStyled
            key={`${slugHelper(article.subtitle)}`}
          >
            <LinkStyled
              title={article.subtitle}
              href={`#${slugHelper(article.subtitle)}`}
              onClick={() => onClick(generateRefNameFor(article.subtitle))}
            >
              {article.subtitle}
              <RightChevronStyled />
            </LinkStyled>
          </ListItemStyled>
        ))}
      </ListStyled>
    </>
  );
};

QuestionsSummary.propTypes = propTypes;
QuestionsSummary.defaultProps = defaultProps;

export default QuestionsSummary;
