// #region types
import type { NextPage } from 'next';
import type { CostGuideSlugPageProps } from 'types/oneflare.com.au/costGuideSlug';
// #endregion types

// #region components
import CostGuide from 'pages/oneflare.com.au/CostGuide';
// #endregion components

// #region utils/helpers
import costGuidesSlugServerSideProps from '@oneflare-server-side-props/_costGuidesSlug';
// #endregion utils/helpers

const Page: NextPage<CostGuideSlugPageProps> = (props) => <CostGuide {...props} />;

export { Page as default, costGuidesSlugServerSideProps as getServerSideProps };
