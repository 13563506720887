import type { CostGuideSlugPageProps } from 'types/oneflare.com.au/costGuideSlug';
import {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  useContext
} from 'react';
import dynamic from 'next/dynamic';
import scrollToComponent from 'react-scroll-to-component-ssr';
import { publicRuntimeConfig } from 'lib/utils/Environment';
import { indefiniteArticleCheck, findAnchorIn } from 'shared/utils/helpers';
import cookieCutter from '@boiseitguru/cookie-cutter';
import { setupExperiment } from 'shared/utils/experiment';
import isEmpty from 'lodash/isEmpty';
import Button from '@oneflare/flarekit/lib/components/Button';
import Hero from 'shared/components/Hero/Hero';
import HeadContent from 'shared/components/_oneflare/HeadContent/HeadContent';
import LazyLoadedSection from 'shared/components/LazyLoadedSection';
import { JobFormControllerContext } from 'lib/oneflare-job-form/utils/context';
import {
  CostStatsStyled,
  CostGuideColStyled,
  FaqStyled,
  H2Styled,
  QuestionSummaryWrapperStyled,
  QuestionSummaryStyled,
  QuestionsAndAnswersSection
} from './styled/CostGuide';
import { popularCities } from './data';
import { experimentJira, experimentVariant, experimentWeights } from 'shared/utils/constants';
import { CTA_TYPES } from 'shared/components/PageHeader/Assets';

// #region components
import RelatedArticles from './components/RelatedArticles/RelatedArticles';
import RelatedGuides from './components/RelatedGuides/RelatedGuides';
import QuestionsAndAnswers from './components/QuestionsAndAnswers/QuestionsAndAnswers';
import QuestionsSummary from './components/QuestionsSummary/QuestionsSummary';
import SampleQuotes from './components/SampleQuotes/SampleQuotes';
import CostGuideHeaderAuthor from './components/CostGuideAuthorHeader/CostGuideAuthorHeader';
import OneflareHeader from 'shared/components/_oneflare/OneflareHeader/OneflareHeader';
import Footer from 'shared/components/Footer';
// lazy loaded components using next/dynamic
const BrowseBy = dynamic(() => import('shared/components/BrowseBy/BrowseBy'), {
  ssr: false
});
const CTASection = dynamic(() => import('shared/components/CTASection/CTASection'), {
  ssr: false
});

// constants
const page = 'Cost Guide';
const experimentalCategories = [
  'car-paint',
  'car-service',
  'septic-tank',
  'duplex',
  'fireplace',
  'shed',
  'tax-accountants',
  'windcreen-repair'
];

// generate breadCrumb
const buildBreadCrumbNav = ({ slug }: Record<string, string>) => [
  {
    title: 'Oneflare',
    link: '/'
  },
  {
    title: 'Cost Guides',
    link: '/costs'
  },
  {
    title: `${slug.replace(/-/g, ' ').replace(/\b([a-z])/g, (c) => c.toUpperCase())}`,
    link: `/costs/${slug}`
  }
];

const useScrollToRef = (refs) => {
  const scrollToRef = useCallback((refName) => scrollToComponent(refs.current[refName], {
    offset: -90, align: 'top', duration: 500, ease: 'inCirc'
  }), [refs]);

  useEffect(() => {
    if (refs.current) {
      scrollToRef(findAnchorIn(window.location.href));
    }
  }, [refs, scrollToRef]);

  return { scrollToRef };
};

const CostGuide = (props: CostGuideSlugPageProps) => {
  const {
    timeToRead,
    wordCount,
    getCostGuide: {
      category,
      modifiedAt,
      createdAt,
      wpSlug,
      hero,
      index,
      relatedArticles,
      quotes,
      stats,
      faqs,
      pageMeta,
      relatedCostGuides
    },
    params
  } = props;
  const getCostGuideAuthor = props?.getCostGuideAuthor;
  const getCostGuideAuthorName = getCostGuideAuthor?.name;

  // #region manage scroll refs
  const refs = useRef({});
  const [activeSection, setActiveSection] = useState('');
  const { scrollToRef } = useScrollToRef(refs);
  // #endregion  manage scroll refs

  const { id, singular, slug, plural } = category || {
    id: '',
    singular: '',
    slug: '',
    plural: ''
  };
  const { controller } = useContext(JobFormControllerContext);
  const handleJobFormLaunch = useCallback(async () => {
    controller?.open({ categoryId: id });
  }, [controller, id]);

  const handleActiveName = useCallback((refName) => {
    const activeName = activeSection === refName ? 'false' : refName;
    setActiveSection(activeName);
  }, [activeSection, setActiveSection]);

  const handleTitleClick = useCallback((refName, applyScroll) => {
    // Make sure the collapse transition has been completed(400ms)
    // otherwise it'll scroll to a wrong position
    if (applyScroll) {
      setTimeout(() => {
        scrollToRef(refName);
      }, 500);
    }
  }, [scrollToRef]);

  const defaultPageTitle = `${singular} - ${(new Date()).getFullYear()} Cost Guide - Oneflare`;
  const autocompleteTitle = singular ? `Where do you need ${indefiniteArticleCheck(singular)?.toLowerCase()}?` : 'Where do you need help?';
  const sessionId = typeof window !== 'undefined' && cookieCutter.get('sessionId');
  const breadCrumbs = useMemo(() => buildBreadCrumbNav({ slug: params.slug}), [params.slug]);
  const { faq, heading } = faqs;
  const mainTitle = hero.heading;
  const metaDescription = pageMeta.description;
  const backgroundImage = hero.backgroundImage?.large;

  return (
    <>
      {sessionId && setupExperiment(experimentJira, experimentVariant, experimentWeights, sessionId)}
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `{
          "@context": "http://schema.org",
          "@type": "Article",
          "@id": "${publicRuntimeConfig.SITE_URL}/costs/${params.slug}#article",
          "publisher": { "@id": "https://www.oneflare.com.au/#organization" },
          "author": [{ "@type": "Person", "name": "${getCostGuideAuthorName ?? 'Oneflare'}" }],
          "headline": "${mainTitle}",
          "description": "${metaDescription}",
          "speakable": {
            "@type": "SpeakableSpecification",
            "cssSelector": ["speakable"]
          },
          "image": [
            "${backgroundImage}"
          ],
          "datePublished": "${createdAt}",
          "dateModified": "${modifiedAt}",
          "wordCount": ${wordCount},
          "timeRequired": "${timeToRead}M",
          "inLanguage": "en",
          "mainEntityOfPage": {
            "@id": "${publicRuntimeConfig.SITE_URL}/costs/${params.slug}#webpage"
          }
        }`
        }}
      />
      <HeadContent
        pageUrl={`${publicRuntimeConfig.SITE_URL}/costs/${params.slug}`}
        pageTitle={pageMeta.pageTitle || defaultPageTitle}
        metaDescription={metaDescription}
        pageShareImg={backgroundImage}
      />
      <OneflareHeader
        isBot={params?.staticRender}
        sessionId={sessionId}
        categoryId={id}
        experimentKind={params.isBot ? 'control' : 'experiment'}
        ctaKind={CTA_TYPES.POST_JOB_AUTOCOMPLETE}
        category={category}
        page={page}
      />
      <Hero
        page={page}
        autocompleteTitle={autocompleteTitle}
        category={category}
        heroImage={backgroundImage}
        mainTitle={mainTitle}
        subTitle={hero.subHeading}
        breadcrumbNav={breadCrumbs}
      />
      <QuestionsAndAnswersSection data-cy="question-and-answer">
        <QuestionSummaryWrapperStyled>
          <QuestionSummaryStyled>
            <QuestionsSummary
              articles={index}
              onClick={scrollToRef}
            />
            {Boolean(relatedArticles?.articles?.length) && (
              <RelatedArticles
                heading={relatedArticles.heading}
                relatedArticles={relatedArticles}
              />
            )}
          </QuestionSummaryStyled>
        </QuestionSummaryWrapperStyled>
        <CostGuideColStyled>
          {experimentalCategories.includes(wpSlug)
            && (
            <CostGuideHeaderAuthor
              authorName={getCostGuideAuthorName ?? ''}
              timeStamp={modifiedAt}
              timeToRead={timeToRead}
            />
            )}
          {index?.map(({ title, description, subtitle }, index) => (
            <QuestionsAndAnswers
              activeSection={activeSection}
              handleTitleClick={handleTitleClick}
              index={index}
              title={title}
              description={description}
              subtitle={subtitle}
              key={title}
              handleActiveName={handleActiveName}
              setRef={(section, refName) => {
                refs.current = { ...refs.current, [refName]: section };
              }}
            />
          ))}
        </CostGuideColStyled>
      </QuestionsAndAnswersSection>
      {!isEmpty(faq) && (
        <FaqStyled
          questions={faq}
          title={heading}
        />
      )}
      <SampleQuotes
        heading={quotes.heading}
        quotes={quotes.quoteCard}
      />
      <CostStatsStyled {...stats} />
      <RelatedGuides
        heading={relatedCostGuides?.heading}
        guides={relatedCostGuides?.costGuide}
      />
      {slug && (
        <BrowseBy
          title={`Find ${plural?.toLowerCase() || 'more'} near you`}
          links={popularCities(slug)}
        />
      )}
      <CTASection>
        <H2Styled>{`Get matched with ${plural || 'businesses'} in your area`}</H2Styled>
        <Button
          onClick={handleJobFormLaunch}
          kind="outline-light-lg"
          label="Get free quotes"
        />
      </CTASection>
      <LazyLoadedSection style={{ minHeight: '218px' }} autoLoad={params?.staticRender}>
        <Footer />
      </LazyLoadedSection>
    </>
  );
};

export default CostGuide;
