import H2 from '@oneflare/flarekit/lib/components/H2';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled } from 'styled-components';

import wysiwygStyles from 'shared/styles/wysiwyg';

export const QuestionsAndAnswersStyled = styled.section`
  overflow: hidden;
  margin-bottom: 40px;

  p {
    margin-bottom: 0;
  }

  ${media.smUp`
     margin-bottom: 64px;
  `}
`;

export const H2Styled = styled(H2)`
  color: ${({ theme }) => theme.color.charcoal};
  font-family: ${({ theme }) => theme.font.secondaryBold};
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 8px;
  padding: 0 8px 0 0;

`;

export const AnswerStyled = styled.div`
  ${wysiwygStyles}
`;

export const TitleSectionStyled = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${media.mdDown`
    cursor: pointer;
  `}
`;
