export const popularCities = (slug) => ([
  {
    title: 'Adelaide',
    url: `/${slug}/sa/adelaide`
  }, {
    title: 'Brisbane',
    url: `/${slug}/qld/brisbane`
  }, {
    title: 'Cairns',
    url: `/${slug}/qld/cairns`
  }, {
    title: 'Canberra',
    url: `/${slug}/act/canberra`
  }, {
    title: 'Hobart',
    url: `/${slug}/tas/hobart`
  }, {
    title: 'Melbourne',
    url: `/${slug}/vic/melbourne`
  }, {
    title: 'Newcastle',
    url: `/${slug}/nsw/newcastle`
  }, {
    title: 'Perth',
    url: `/${slug}/wa/perth`
  }, {
    title: 'Sydney',
    url: `/${slug}/nsw/sydney`
  }
]);
