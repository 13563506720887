import { useState } from 'react';
import parse from 'html-react-parser';
import CollapsibleContainer from '@oneflare/flarekit/lib/components/CollapsibleContainer';
import * as Styled from './styled/Accordion';

type AccordionType = {
  content: string;
  downgradeHeadingLevel?: boolean;
  title: string | Array<string>;
};

function Accordion({
  title,
  content,
  downgradeHeadingLevel = false
}: AccordionType) {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <Styled.AccordionContainer>
      <Styled.AccordionHeader onClick={() => setExpanded((expanded) => !expanded)}>
        <AccordionTitle title={title} downgradeHeadingLevel={downgradeHeadingLevel} />
        <Styled.Chevron toggled={isExpanded} />
      </Styled.AccordionHeader>
      <CollapsibleContainer expanded={isExpanded}>
        <Styled.AccordionInner>
          {parse(content)}
        </Styled.AccordionInner>
      </CollapsibleContainer>
    </Styled.AccordionContainer>
  );
}

function AccordionTitle({ title, downgradeHeadingLevel = false }: { title: string | Array<string>; downgradeHeadingLevel?: boolean; }) {
  if (downgradeHeadingLevel) return <Styled.DowngradedTitle>{title}</Styled.DowngradedTitle>;
  return <Styled.Title>{title}</Styled.Title>;
}

export default Accordion;
