import { styled } from 'styled-components';
import media from '@oneflare/flarekit/lib/libs/media';
import H2 from '@oneflare/flarekit/lib/components/H2';
import RightChevron from '@oneflare/flarekit/lib/components/icons/RightChevron/RightChevron';
import Anchor from '@oneflare/flarekit/lib/components/Anchor';

export const H2Styled = styled(H2)`
  color: ${({ theme }) => theme.color.charcoal};
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 16px;
  font-weight: 700;
  margin: 0;
`;

export const ListStyled = styled.ul`
  list-style: none;
  margin: 0;
  max-width: 320px;
  padding: 0;
`;

export const ListItemStyled = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.color.nickel};
`;

export const LinkStyled = styled(Anchor)`
  color: ${({ theme }) => theme.color.gunMetal};
  cursor: pointer;
  display: block;
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  position: relative;
  padding: 16px 24px 16px 0;
  width: 100%;

  &:hover {
    color: ${({ theme }) => theme.color.primary};
    text-decoration: none;

    svg {
      fill: ${({ theme }) => theme.color.primary};
      stroke: ${({ theme }) => theme.color.primary};
      stroke-width: 3px;
    }
  }

  &:last-child {
    border: none;
  }

  ${media.lgUp`
    font-size: 16px;
  `}
`;

export const RightChevronStyled = styled(RightChevron).attrs(({ theme }) => ({
  fill: theme.color.gunMetal,
  width: 12,
  height: 12,
  strokeWidth: 4
}))`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;
