import { styled } from 'styled-components';
import media from '@oneflare/flarekit/lib/libs/media';
import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import H2 from '@oneflare/flarekit/lib/components/H2';

export const H2Styled = styled(H2)`
  color: ${({ theme }) => theme.color.charcoal};
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 16px;
  font-weight: bold;
  margin: 32px 0 16px;
`;

export const ListStyled = styled.ul`
  list-style: none;
  max-width: 320px;
  padding: 0;
`;

export const ListItemStyled = styled.li`
  margin-bottom: 16px;
`;

export const LinkStyled = styled(Anchor)`
  color: ${({ theme }) => theme.color.infoDark};
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 14px;
  font-weight: 400;

  ${media.lgUp`
    font-size: 16px;
  `}
`;
