// #region types
import type { ICategoryModel } from 'types/shared';
// #endregion types

// #region components
import PageHeader from 'shared/components/PageHeader/PageHeader';
// #endregion components

// #region utils/helpers
import { Experiment, Variant } from '@marvelapp/react-ab-test';
import { experimentJira, experimentVariant } from 'shared/utils/constants';
// #endregion utils/helpers

type OneflareHeaderProps = {
  experimentKind: 'control' | 'experiment';
  kind?:  'overlay' | 'default' | 'bordless' | 'overlayDark' | 'overlayLight';
  category: ICategoryModel;
  categoryId?: string | number;
  directLeadsNumberFormatted?: string;
  ctaKind?: string;
  hideStickyHeader?: boolean;
  sessionId?: string;
  isBot?: boolean;
  page?: string;
};

const OneflareHeader = ({
  kind,
  experimentKind,
  category,
  categoryId,
  sessionId,
  directLeadsNumberFormatted,
  ctaKind,
  hideStickyHeader,
  isBot
}: OneflareHeaderProps) => {
  switch (experimentKind) {
    case 'control':
      return (
        <PageHeader
          kind={kind}
          category={category}
          ctaKind={ctaKind}
          categoryId={categoryId ?? category?.id}
          directLeadsNumber={directLeadsNumberFormatted}
          hideStickyHeader={hideStickyHeader}
        />
      );
    default:
      return (
        <Experiment name={experimentJira} userIdentifier={sessionId || ''}>
          <Variant name={experimentVariant[0]}>
            <PageHeader
              kind={kind}
              category={category}
              ctaKind={ctaKind}
              categoryId={categoryId ?? category?.id}
              directLeadsNumber={directLeadsNumberFormatted}
              hideStickyHeader={hideStickyHeader}
            />
          </Variant>
          <Variant name={experimentVariant[1]}>
            <PageHeader
              kind={kind}
              category={category}
              ctaKind={ctaKind}
              categoryId={categoryId ?? category?.id}
              directLeadsNumber={directLeadsNumberFormatted}
              isBot={isBot}
              hideStickyHeader={hideStickyHeader}
            />
          </Variant>
        </Experiment>
      );
  }
};

OneflareHeader.defaultProps = {
  directLeadsNumberFormatted: '',
  hideStickyHeader: false,
  ctaKind: undefined,
  categoryId: null,
  sessionId: '',
  kind: undefined,
  isBot: false,
  page: ''
};

export default OneflareHeader;
